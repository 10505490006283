define("discourse/plugins/discourse-encrypt/discourse/connectors/after-topic-status/encrypted-status", ["exports", "discourse/helpers/d-icon", "discourse-i18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _dIcon, _discourseI18n, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EncryptedStatus = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    {{~#if @outletArgs.topic.encrypted_title~}}
      <span
        title={{i18n "topic-statuses.encrypted.help"}}
        class="topic-status"
      >{{icon "user-secret"}}</span>
    {{~/if~}}
  
  */
  {
    "id": "vFk+1x2D",
    "block": "[[[41,[30,1,[\"topic\",\"encrypted_title\"]],[[[10,1],[15,\"title\",[28,[32,0],[\"topic-statuses.encrypted.help\"],null]],[14,0,\"topic-status\"],[12],[1,[28,[32,1],[\"user-secret\"],null]],[13]],[]],null]],[\"@outletArgs\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-encrypt/discourse/connectors/after-topic-status/encrypted-status.js",
    "scope": () => [_discourseI18n.i18n, _dIcon.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "encrypted-status:EncryptedStatus"));
  var _default = _exports.default = EncryptedStatus;
});