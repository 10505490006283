define("discourse/plugins/discourse-encrypt/discourse/api-initializers/encrypt-icons", ["exports", "@ember/object", "discourse/lib/api", "discourse-common/helpers/d-icon", "discourse-common/helpers/i18n", "discourse-common/lib/deprecated", "discourse-i18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _object, _api, _dIcon, _i18n, _deprecated, _discourseI18n, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _api.apiInitializer)("2.0.0", api => {
    (0, _deprecated.withSilencedDeprecations)("discourse.hbr-topic-list-overrides", () => {
      let topicStatusIcons;
      try {
        topicStatusIcons = require("discourse/helpers/topic-status-icons").default;
      } catch {}
      if (topicStatusIcons && !topicStatusIcons.entries.find(_ref => {
        let {
          attribute
        } = _ref;
        return attribute === "encrypted_title";
      })) {
        topicStatusIcons?.addObject(["encrypted_title", "user-secret", "encrypted"]);
      }
      // topic-list-item icon
      api.modifyClass("raw-view:topic-status", {
        pluginId: "encrypt",
        statuses: (0, _object.computed)(function () {
          const results = this._super(...arguments);
          if (this.topic.encrypted_title) {
            results.push({
              openTag: "span",
              closeTag: "span",
              title: _discourseI18n.default.t("topic-statuses.encrypted.help"),
              icon: "user-secret",
              key: "encrypted"
            });
          }
          return results;
        })
      });
    });
    // Main topic title
    api.renderInOutlet("after-topic-status", (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if @outletArgs.topic.encrypted_title}}
          <span
            title={{i18n "topic-statuses.encrypted.help"}}
            class="topic-status encrypted"
          >{{icon "user-secret"}}</span>
        {{/if}}
      
    */
    {
      "id": "qNoCa5XM",
      "block": "[[[1,\"\\n\"],[41,[30,1,[\"topic\",\"encrypted_title\"]],[[[1,\"      \"],[10,1],[15,\"title\",[28,[32,0],[\"topic-statuses.encrypted.help\"],null]],[14,0,\"topic-status encrypted\"],[12],[1,[28,[32,1],[\"user-secret\"],null]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@outletArgs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-encrypt/discourse/api-initializers/encrypt-icons.js",
      "scope": () => [_i18n.default, _dIcon.default],
      "isStrictMode": true
    }), (0, _templateOnly.default)(undefined, undefined)));
  });
});